import { Link } from "react-router-dom";
import Header from "../components/Header/Header";
import PageBanner from "../components/about/PageBanner";
import Counter from "../components/Counter";
import Footer from "../components/Footer/Footer";
import { Fade } from "react-awesome-reveal";
import AboutCube from "../components/about/AboutCube";
import ImageMasonry from "../components/masonary/MasonaryAbout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchTranslations } from "../actions/languageActions";
import NewHeader from "../components/Header/NewHeader";
import Loading from "../components/loading/Loading";
import FormModal from "../elements/FormModal";
import { Helmet } from "react-helmet-async";
import FormModalOne from "../elements/FormModalOne";
// import Typewriter from "typewriter-effect";
const handleChnge = () => {
  onclick = "window.print()";
};
const FirstAid = () => {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  const translations = useSelector((state) => state.language.translations[selectedLanguage]);
  
  useEffect(() => {
    dispatch(fetchTranslations(selectedLanguage));
  }, [dispatch, selectedLanguage]);
  
  if (!translations) {
    return <div>
      <Loading/>
    </div>; // Show loading state while translations are being fetched
  }
  
  const { aboutus } = translations;
  const industryList = aboutus.industry.list;
  const learnUs = aboutus.learnUs.list;
  return (
    <>
      <Helmet>
<title>First Aid | Best Certificate & Diploma Courses | Signet Institute</title>
<meta name='description' content='Signet Institute of Australia are BASED IN MELBOURNE, SYDNEY & ADELAIDE. (13+ YEARS Experience) Providing Best Certificate & Diploma Courses.'data-rh="true" />
</Helmet>
      <NewHeader />
      {/* <PageBanner pageName={"About us"} /> */}

      {/* <Fade delay={1e3} triggerOnce cascade damping={1e-5}> */}

      {/* page banner */}

      {/* page banner */}
      {/* Page Banner End */}

      <div className="about-sec-">
        <section class="pt-150 nanhe">
        
          <div className="container">
            <div className="row">
              <div className="col-md-6 pt-60">
                <h2 className="title-banner ">
                Provide First Aid
                </h2>
                <p>Embrace the opportunity to grow, develop, and achieve your goals with Signet Institute of Australia. Take the first step towards a brighter future by exploring our Provide First Aid below and embark on a transformative educational journey like no other.</p>
                <a href="#popup1" className="theme-btn my-15" id>
                       Book Now 
                </a>
              </div>
              <div className="col-md-6 ">
                <div className="banner-ab-image text-center">
                  <img src="/images/first_aid.jpg"/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="about-page-section pt-60 pb-65 rpt-90">
            <div className="container">
                <div className="row">
                   <div className="col-md-8 pt-20">
                        <div class="whitebg">
                          <h5>Course Description </h5>
                            <p>This program covers the essential abilities and understanding needed to identify and handle life-threatening crises effectively, offering initial assistance to an injured individual across various scenarios. It is designed for individuals in professional environments and members of the community seeking a thorough first aid education. Formerly recognised as HLTAID003, Senior First Aid, Workplace Level 2, and Apply First Aid, the course also provides CPR training. Core topics include implementing the DRSABCD action plan during emergencies, administering cardiopulmonary resuscitation (CPR), managing burns, asthma attacks, anaphylaxis, sprains, and strains.</p>
                        </div>
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-2" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-2"><h5>Mode Of Delivery </h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                                <p><strong>Blended</strong></p>
                                <p><ul><li>Self-Study: The program has self-study components that will be accessed by the student in the Canvas Learning Management System</li>
                                <li>Supervised Delivery: The program will be conducted in a structured classroom environment.</li>
                                </ul></p>
                                </div>
                          </div>
                        </div>
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-3" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-3"><h5>Locations Offered </h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                              <p><ul><li>Melbourne - 341 Queen St, Level 5, 9, Melbourne, Victoria 3000 </li>
                            </ul></p> 
                            </div>
                          </div>
                        </div>
                        
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-4" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-4"><h5>Entry Requirements</h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p>NIL</p>
                            </div>
                          </div>
                        </div>
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-5" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-5"><h5>LLN, PTR, & Computer </h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p><ul><li>LLN = Internet-based Language, Literacy and Numeracy test to ensure that you have the fundamental skills to complete this qualification.</li>
                                  <li>PTR = Pre-Training Review is an assessment of your past learning, experience and skill development to ensure that you can understand the concepts delivered in this qualification.</li>
                                  <li>COMPUTER = You should possess sound computer skills, ability to navigate internet and use various software tools to study, produce Assessments and absorb the training material.</li>
                                </ul></p>
                            </div>
                          </div>
                        </div>
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-6" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-6"><h5>Assessments </h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p>Each candidate is expected to showcase their competencies in an environment that offers immersive, realistic scenarios and simulations to evaluate their skills and understanding. Given the nature of this training approach, it is acceptable for performance evidence to be gathered in a simulated setting. Demonstration of compression and ventilation techniques must adhere to ARC guidelines on resuscitation mannequins for CPR assessment purposes. Assessment protocols must ensure access to:
                                <ul><li>Resuscitation manikins for adults and infants, conforming to ARC guidelines for CPR evaluation</li>
                                  <li>Adrenaline auto-injector training device</li>
                                  <li>Automated External Defibrillator (AED) training devices</li>
                                  <li>Workplace first aid kit</li>
                                  <li>Placebo bronchodilator and spacer device</li>
                                  <li>Various wound dressings and bandages</li>
                                  <li>Blankets and materials for treating shock</li>
                                  <li>Personal protective equipment (PPE)</li>
                                  <li>Workplace injury, trauma, or illness record, or other relevant workplace or site incident report forms</li>
                                  </ul></p>
                            <p>Simulated assessment environments should replicate real-life scenarios where these skills and knowledge would be utilised, with all pertinent equipment and resources available in that particular workplace or community context. Assessors must meet the Standards for Registered Training Organisations' criteria for assessors and possess this unit or demonstrate commensurate skills and knowledge contained within it.</p>
                            </div>
                          </div>
                        </div>
                        <div id="booknow" class="col-md-12 pt-40">
                        <h4><strong>LIMITED SEATS AVAILABLE!</strong></h4>
                        <p>Don't miss out on this opportunity. Reserve your spot now by filling out the form below. Our team will reach out to confirm your enrollment and provide further details about the course.</p>
                      <div id="popup1" class="overlay">
                        <div class="popup">
                          <a class="close" href="#">&times;</a>
                          <div class="content">
                          <p><iframe src="https://signetinstituteofaustralia.getomnify.com/widgets/TGS0OKNPPBBR8PFTA20OJ" frameborder="0" scrolling="yes" seamless="seamless"></iframe></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <iframe src="https://signetinstituteofaustralia.getomnify.com/widgets/TGS0OKNPPBBR8PFTA20OJ" frameborder="0" scrolling="yes" seamless="seamless"></iframe>
                    </div>
                    <div class="col-md-4">
                        <div className="course-sidebar rmt-75">
                            <div className="widget widget-course-details wow fadeInUp delay-0-2s text-center">
                                <img src="/img/about/course-details.png" />
                                <ul className="course-details-list mt-20 mb-25">
                                    <li>
                                        <i className="far fa-file-alt" />{" "}
                                        <span>Course Code:</span>{" "}
                                        <b>HLTAID011</b>
                                    </li>
                                    <li>
                                        <i className="far fa-clock" />{" "}
                                        <span>CRICOS Code:</span>{" "}
                                        <b>NA</b>
                                    </li>
                                    <li>
                                        <i className="far fa-play-circle" />{" "}
                                        <span>Duration:</span>{" "}
                                        <b>1 days</b>
                                    </li>
                                    <li>
                                        <i className="far fa-clipboard" />{" "}
                                        <span>Faculty:</span>{" "}
                                        <b>Health Course</b>
                                    </li>
                                    <li>
                                        <i className="far fa-clipboard" />{" "}
                                        <span>Course Fee:</span>{" "}
                                        <b>$190</b>
                                    </li>
                                    </ul>
                                    <p className="">
                                        <FormModalOne  />
                                    </p>
                                    <a href="#popup1" className="theme-btn mt-10">
                                    Book Now <i className="fas fa-arrow-right" />
                                    </a>
                            </div>
                            <div className="widget widget-menu wow fadeInUp delay-0-2s mt--30">
                                <h5 className="widget-title"><a href="https://signet.edu.au/automotive-courses">Automotive Courses (3)</a></h5>
                                <h5 className="widget-title"><a href="https://signet.edu.au/business-courses">Business Courses (3)</a></h5>
                                <h5 className="widget-title"><a href="https://signet.edu.au/construction-courses">Construction Courses (5)</a></h5>
                                <h5 className="widget-title"><a href="https://signet.edu.au/community-courses">Community Courses (5)</a></h5>
                                <h5 className="widget-title"><a href="https://signet.edu.au/healthcare-courses">Healthcare Courses (3)</a></h5>
                                <h5 className="widget-title"><a href="https://signet.edu.au/general-english">General English (1)</a></h5>
                                <h5 className="widget-title"><a href="https://signet.edu.au/manufacturing-and-engineering">Manufacturing and Engineering (1)</a></h5>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
      </div>
      {/* </Fade> */}
      <Footer />
    </>
  );
};
export default FirstAid;
